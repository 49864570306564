import React, { memo } from 'react';

import { Props } from './types';
import { Container } from './styles';

const Loader: React.FC<Props> = memo(() => {
  return <Container>Loading...</Container>;
});

export { Loader };
