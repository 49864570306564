import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
`;

export const InnerContainer = styled.div`
  min-height: 100vh;
  overflow-y: hidden;
  position: relative;
  padding: ${({ theme }) => `${theme.padding.base(3)}`};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  flex-direction: column;

  &.page-content-container-without-fixed-size {
    height: auto;
  }

  ${({ theme: { utils, screen, padding } }) => utils.media.maxWidth(screen.tablet.lg)`
    padding: ${padding.base(4)};
    height: auto;
  `}

  ${({ theme: { utils, screen, padding } }) => utils.media.maxWidth(screen.mobile.lg)`
    padding: ${padding.base(2)};
    height: auto;
  `}
`;

export const FooterContainer = styled.div`
  padding: ${({ theme }) => `${theme.padding.base(10)} ${theme.padding.base(14)}`};
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme: { utils, screen, padding } }) => utils.media.maxWidth(screen.tablet.lg)`
    padding: ${padding.base(8)} ${padding.base(4)};
  `}
`;

export const CopyrightTextContainer = styled.div`
  color: ${({ theme }) => theme.utils.hexToRgba(theme.colors.white, 0.6)};
`;

export const LinksContainer = styled.div`
  flex: 1;
`;

export const BaseLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
`;

export const MailLink = styled(BaseLink)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.padding.base(1)}`};
`;
