import React, { memo } from 'react';

import { Props, TextVariants, TextAsVariants } from './types';
import { StyledText } from './styles';

const Text: React.FC<Props> = memo(
  ({ children, variant = TextVariants.Body, as = TextAsVariants.P, ...props }) => {
    return (
      <StyledText {...props} as={as} $variant={variant}>
        {children}
      </StyledText>
    );
  }
);

export { Text, TextVariants, TextAsVariants };
