import React from 'react';
import { DataRouteObject, Navigate } from 'react-router-dom';

import { RouteIds, Routes } from './constants';

const CleanerPage = React.lazy(() => import('pages/Cleaner'));

const routes: DataRouteObject[] = [
  {
    id: RouteIds.Home,
    path: Routes.Home,
    element: <Navigate to={Routes.Cleaner} replace />,
  },
  {
    id: RouteIds.Cleaner,
    path: Routes.Cleaner,
    element: <CleanerPage />,
  },
];

export { routes };
