import React, { memo, useMemo } from 'react';

import { ReactComponent as MailIcon } from 'assets/images/mail.svg';

import { Text, TextVariants, TextAsVariants } from 'modules/common/components/Text';
import { Row } from 'modules/common/components/Row';
import { Column } from 'modules/common/components/Column';
import { useWindowSize } from 'modules/common/hooks/useWindowSize';

import { Props } from './types';
import {
  Container,
  InnerContainer,
  FooterContainer,
  CopyrightTextContainer,
  LinksContainer,
  MailLink,
  BaseLink,
} from './styles';

const PageLayout: React.FC<Props> = memo(({ children }) => {
  const { isTablet } = useWindowSize();

  const footerLinks = useMemo(
    () => (
      <>
        <Text variant={TextVariants.Body} as={TextAsVariants.Div}>
          <MailLink href='mailto:support@awesomeapps.pro'>
            <MailIcon />

            <span>support@awesomeapps.pro</span>
          </MailLink>
        </Text>

        <Text variant={TextVariants.Body}>
          <BaseLink href='cleaner/privacy'>Privacy Policy</BaseLink>
        </Text>

        <Text variant={TextVariants.Body}>
          <BaseLink href='cleaner/terms'>Terms of Use</BaseLink>
        </Text>
      </>
    ),
    []
  );

  const footerContent = useMemo(
    () => (
      <>
        <CopyrightTextContainer>
          <Text variant={TextVariants.Body}>
            Copyright © 2024 Awesome Apps. All rights reserved.
          </Text>
        </CopyrightTextContainer>

        <LinksContainer>
          {isTablet ? (
            <Column $gap={4}>{footerLinks}</Column>
          ) : (
            <Row $gap={3} $justifyContent='flex-end'>
              {footerLinks}
            </Row>
          )}
        </LinksContainer>
      </>
    ),
    [footerLinks, isTablet]
  );

  return (
    <Container>
      <InnerContainer id='page-content-container'>{children}</InnerContainer>

      <FooterContainer>
        {isTablet ? (
          <Column $gap={4} $isReversed>
            {footerContent}
          </Column>
        ) : (
          <Row $justifyContent='space-between' $gap={7.75}>
            {footerContent}
          </Row>
        )}
      </FooterContainer>
    </Container>
  );
});

export { PageLayout };
