import React from 'react';

export enum TextAsVariants {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  P = 'p',
  Div = 'div',
  Span = 'span',
}

export enum TextVariants {
  Title = 'title',
  TitleBold = 'titleBold',

  Subtitle = 'subtitle',
  SubtitleBold = 'subtitleBold',

  Body = 'body',
  BodyBold = 'bodyBold',

  Caption = 'caption',
  CaptionBold = 'captionBold',
}

export type Props = React.PropsWithChildren & {
  variant?: TextVariants;
  as?: TextAsVariants;
};
