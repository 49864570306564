import ReactDOM from 'react-dom/client';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Router } from './modules/router/components/Router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Router />);
