import { css } from 'styled-components';
import { Styles, Interpolation } from 'styled-components/dist/types';

import hexToRgba from 'hex-to-rgba';

import { Theme, Screen } from './types';

const PADDING_BASE = 8;

const screen: Screen = {
  desktop: {
    sm: 1280,
    md: 1440,
    lg: 1920,
  },
  tablet: {
    sm: 768,
    md: 1279,
    lg: 1279,
  },
  mobile: {
    sm: 360,
    md: 420,
    lg: 767,
  },
};

const media = {
  maxWidth:
    (width: number) =>
    (styles: Styles<object>, ...interpolations: Interpolation<object>[]) =>
      css`
        @media screen and (max-width: ${width}px) {
          ${css(styles, ...interpolations)};
        }
      `,

  minWidth:
    (width: number) =>
    (styles: Styles<object>, ...interpolations: Interpolation<object>[]) =>
      css`
        @media screen and (min-width: ${width}px) {
          ${css(styles, ...interpolations)};
        }
      `,
};

export const theme: Theme = {
  colors: {
    white: '#ffffff',
    black: '#222222',
    blue: '#3652FF',
    purple: '#C7A6FF',
    pink: '#FF6AAB',
    greenBlue: '#9CE4E3',
    lightGrey: '#F5F5F5',
  },
  fonts: {
    primary: "'Roboto', sans-serif",
  },
  padding: {
    base: (value: number) => `${value * PADDING_BASE}px`,
    baseNumber: (value: number) => value * PADDING_BASE,
  },
  utils: {
    hexToRgba,
    media,
  },
  screen,
};

export type { Theme };
