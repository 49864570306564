import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { FIREBASE_CONFIG } from './config';
import { AnalyticEvents } from './constants';

const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);

const AnalyticsService = {
  logEvent: (
    eventName: AnalyticEvents,
    eventParams?:
      | {
          [key: string]: any;
        }
      | undefined
  ) => {
    logEvent(analytics, eventName, eventParams);
  },
};

export { AnalyticsService, AnalyticEvents };
