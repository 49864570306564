import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { createRouter } from './createRouter';
import { Routes } from './constants';

const router = createRouter();

const Router: React.FC = () => <RouterProvider router={router} />;

export { Router, Routes };
