import React, { useEffect } from 'react';

import { ThemeProvider } from './modules/common/containers/ThemeProvider';
import { RouteWrapper } from './modules/router/components/RouteWrapper';
import { PageLayout } from './modules/common/components/PageLayout';
import { AnalyticEvents, AnalyticsService } from './modules/firebase';

const App: React.FC = () => {
  useEffect(() => {
    AnalyticsService.logEvent(AnalyticEvents.SiteOpened);
  }, []);

  return (
    <ThemeProvider>
      <PageLayout>
        <RouteWrapper />
      </PageLayout>
    </ThemeProvider>
  );
};

export { App };
