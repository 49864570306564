import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from 'modules/common/components/Loader';

import { LoaderContainer } from './styles';

const RouteWrapper: React.FC = () => {
  return (
    <Suspense
      fallback={
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export { RouteWrapper };
