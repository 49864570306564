import { useMemo, useRef } from 'react';

import { theme } from 'theme';

import { WindowSize } from './types';

export const useWindowSize = () => {
  const windowSize = useRef<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  }).current;

  const isMobile = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= theme.screen.mobile.lg),
    [windowSize.width]
  );

  const isTablet = useMemo(
    () => Boolean(windowSize.width && windowSize.width <= theme.screen.tablet.lg),
    [windowSize.width]
  );
  return {
    windowSize,
    isMobile,
    isTablet,
  };
};
