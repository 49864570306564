import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    height: 100%;
  }
  
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.colors.black};
    height: 100%;

    &.size-locked {
      overflow: hidden;
    }
  }

  #root {
    height: 100%;
  }
  
  a {
    text-decoration: none;
  }
`;
