import React, { memo } from 'react';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';

import { theme } from 'theme';
import { GlobalStyles } from 'theme/styles';

const ThemeProvider: React.FC<React.PropsWithChildren> = memo(({ children }) => {
  return (
    <BaseThemeProvider theme={theme}>
      <GlobalStyles />

      {children}
    </BaseThemeProvider>
  );
});

export { ThemeProvider };
