import styled, { css } from 'styled-components';

import { TextVariants } from './types';

const titleStyles = css`
  font-size: 64px;
  line-height: 70.4px;
  font-weight: 400;

  ${({ theme: { utils, screen } }) => utils.media.maxWidth(screen.tablet.lg)`
    font-size: 40px;
    line-height: 44px;
  `}
`;

const titleBoldStyles = css`
  ${titleStyles}

  font-weight: 700;
`;

const subtitleStyles = css`
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;

  ${({ theme: { utils, screen } }) => utils.media.maxWidth(screen.tablet.lg)`
    font-size: 17px;
    line-height: 22px;
  `}
`;

const subtitleBoldStyles = css`
  ${subtitleStyles}

  font-weight: 700;
`;

const bodyStyles = css`
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;

  ${({ theme: { utils, screen } }) => utils.media.maxWidth(screen.tablet.lg)`
    font-size: 16px;
    line-height: 18.75px;
  `}
`;

const bodyBoldStyles = css`
  ${bodyStyles}

  font-weight: 700;
`;

const captionStyles = css`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;

  ${({ theme: { utils, screen } }) => utils.media.maxWidth(screen.tablet.lg)`
    font-size: 16px;
    line-height: 18.75px;
  `}
`;

const captionBoldStyles = css`
  ${captionStyles}

  font-weight: 700;
`;

const stylesByVariant = {
  [TextVariants.Title]: titleStyles,
  [TextVariants.TitleBold]: titleBoldStyles,

  [TextVariants.Subtitle]: subtitleStyles,
  [TextVariants.SubtitleBold]: subtitleBoldStyles,

  [TextVariants.Body]: bodyStyles,
  [TextVariants.BodyBold]: bodyBoldStyles,

  [TextVariants.Caption]: captionStyles,
  [TextVariants.CaptionBold]: captionBoldStyles,
};

export const StyledText = styled.p<{ $variant: TextVariants }>`
  ${({ $variant }) => stylesByVariant[$variant]}
`;
