import styled from 'styled-components';

export const Column = styled.div<{
  $gap?: number;
  $justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  $alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  $disabled?: boolean;
  $isReversed?: boolean;
}>`
  flex-direction: column;
  flex-direction: ${({ $isReversed }) => ($isReversed ? 'column-reverse' : 'column')};
  display: ${({ $disabled }) => ($disabled ? 'block' : 'flex')};
  align-items: ${({ $alignItems = 'flex-start' }) => $alignItems};
  justify-content: ${({ $justifyContent = 'flex-start' }) => $justifyContent};
  gap: ${({ theme, $gap }) => ($gap ? theme.padding.base($gap) : 0)};
`;
